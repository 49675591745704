.b-table .is-image-cell .image img {
  max-height: 100%;
}

@media screen and (max-width: 768px) {
  .b-table .table.has-mobile-cards thead {
    display: none;
  }

  .b-table .table.has-mobile-cards tfoot th {
    border: 0;
    display: inherit;
  }

  .b-table .table.has-mobile-cards tr {
    -webkit-box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1),
      0 0 0 1px rgba(10, 10, 10, 0.1);
    box-shadow: 0 2px 3px rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.1);
    max-width: 100%;
    position: relative;
    display: block;
  }

  .b-table .table.has-mobile-cards tr td {
    border: 0;
    display: inherit;
  }

  .b-table .table.has-mobile-cards tr td:last-child {
    border-bottom: 0;
  }

  .b-table .table.has-mobile-cards tr:not(:last-child) {
    margin-bottom: 1rem;
  }

  .b-table .table.has-mobile-cards tr:not([class*='is-']) {
    background: inherit;
  }

  .b-table .table.has-mobile-cards tr:not([class*='is-']):hover {
    background-color: inherit;
  }

  .b-table .table.has-mobile-cards tr.detail {
    margin-top: -1rem;
  }

  .b-table
    .table.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: auto;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    text-align: right;
    border-bottom: 1px solid #f5f5f5;
  }

  .b-table
    .table.has-mobile-cards
    tr:not(.detail):not(.is-empty):not(.table-footer)
    td:before {
    content: attr(data-label);
    font-weight: 600;
    padding-right: 0.5em;
    text-align: left;
  }

  .b-table
    .table.has-mobile-cards
    td.has-no-head-mobile.is-image-cell
    .image {
      height: 25vw !important;
    }
}

.pagination-link {
  background-color: white;
}

.table.is-hoverable.is-striped
  tbody
  tr:not(.is-selected):hover:nth-child(even) {
  background-color: #e2e0e0;
}
